import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";
import { JobStyle } from "@headbot/library";

interface IResponse {
    readonly jobs?: Array<{
        readonly created: string;
        readonly _id: string;
        readonly input: {
            readonly gender: "male" | "female" | "unspecified";
            readonly sourceFileIds: Array<string>;
        };
        readonly outputFileIds: Array<string>;
        readonly style: JobStyle;
    }>;
}

const getJobs = async (config: AxiosRequestConfig) => {
    const uri = `${Constants.ApiRoot}/jobs`;
    const { data } = await axios.get<IResponse>(uri, config);
    return data;
};

export const useJobs = () => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.Jobs], () => getJobs(config));
};
