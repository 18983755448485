import * as React from "react";
import { useTranslation } from "react-i18next";
import { PaypalProvider } from "../../layout/PaypalProvider/PaypalProvider";
import { WithAuth } from "../../molecules/Auth/WithAuth";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { Jobs } from "./Jobs/Jobs";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";

export const DashboardPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const description = t("dashboardPage.description");
    const title = t("dashboardPage.title");
    return (
        <PaypalProvider>
            <ScrollablePageTemplate title={title} description={description} canonical={HeadbotRoute.Dashboard}>
                <WithAuth>
                    <Jobs />
                </WithAuth>
            </ScrollablePageTemplate>
        </PaypalProvider>
    );
};
