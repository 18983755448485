import { IconCheck, IconClock } from "@tabler/icons-react";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getRandomElement } from "../../../../functions/getRandomElement";
import { getRelativeTime } from "../../../../functions/getRelativeTime";
import { useJobs } from "../../../../hooks/api/jobs/useJobs";
import { HeadbotRoute } from "../../../../services/Constants/HeadbotRoute";
import { SecondaryButtonLink } from "../../../atoms/ButtonLink/SecondaryButtonLink";
import { H4 } from "../../../atoms/H4/H4";
import { Spinner } from "../../../atoms/Spinner/Spinner";
import { JobImage } from "../../../molecules/JobImage/JobImage";
import { Title } from "../../BuffPage/BuffPage.styles";
import { Content, ContentRoot, JobListItem, JobListRoot, Root } from "./Jobs.styles";
import { Color } from "../../../layout/GlobalStyles/Color";

interface IProps {}
export const Jobs: React.FC<IProps> = () => {
    const [t] = useTranslation();
    const jobsData = useJobs();
    const jobsList = useMemo(
        () =>
            jobsData.data?.jobs?.map((purchase) => {
                const randomInputImage = getRandomElement(purchase.input.sourceFileIds);
                const randomOutputImage = getRandomElement(purchase.outputFileIds);
                const imageId = randomOutputImage ?? randomInputImage;
                const isJobCompleted = randomOutputImage !== null;
                const statusIcon = isJobCompleted === true ? <IconCheck color={Color.Green100} /> : <IconClock />;

                return (
                    <JobListItem key={purchase._id}>
                        <H4>{getRelativeTime(t, purchase.created)}</H4>
                        {statusIcon}
                        <JobImage jobId={purchase._id} imageId={imageId} width={200} height={200} canOpen={isJobCompleted} />
                        <SecondaryButtonLink to={HeadbotRoute.ManageJob(purchase._id)}>{t("dashboardPage.manageJob")}</SecondaryButtonLink>
                    </JobListItem>
                );
            }),
        [jobsData.data]
    );
    const jobs = useMemo(() => {
        return <JobListRoot>{jobsList}</JobListRoot>;
    }, [jobsList]);

    if (jobsData.isLoading) {
        return <Spinner />;
    }

    return (
        <Root>
            <ContentRoot>
                <Title>{t("dashboardPage.jobs")}</Title>
                <Content>{jobs}</Content>
            </ContentRoot>
        </Root>
    );
};
